.df-mosaic header.df-header:not(#\#) .df-nav-item .df-context-menu-overlay.position-right-overide {
    left: auto;
    right: 0;
}

.df-nav-item.open:after {
    content: '';
    display: block;
    position: fixed;
    top: 70px;
    lefT: 0; right: 0;
    bottom: 0;
    background-color: rgba(0,0,0,0.6);

    z-index: 1;
    
}

.df-context-menu-overlay {
    z-index: 10;
}